import React, { useState, useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";

function Contact() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the state

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "free-consultation" });
      cal("ui", { hideEventTypeDetails: false, layout: "month_view" });
    })();
  }, []);

  return (
    <>
      <div className="contact pt-5">
        <div className="w-100 p-md-5 p-3">
          <h3
            style={{
              color: "grey",
              fontSize: "clamp(2rem, 3vw, 5rem)",
              textAlign: "left",
              width: "70%",
              paddingLeft: "10%",
            }}
          >
            READY TO FIND OUT IF WE’RE THE{" "}
            <span style={{ color: "#FC5D56" }}>ONES</span> FOR YOU?
          </h3>
          <p
            style={{
              fontSize: "clamp(1rem, 3vw, 2rem)",
              textAlign: "left",
              width: "70%",
              paddingLeft: "10%",
            }}
          >
            Schedule your <span style={{ color: "#FC5D56" }}>FREE</span>,
            20-minute strategy session with us
          </p>

          <Cal
            namespace="free-consultation"
            calLink="software-strategic-solutions/free-consultation"
            style={{ width: "100%", height: "70%", overflow: "scroll" }}
            config={{ layout: "month_view" }}
          />
        </div>
      </div>
    </>
  );
}

export default Contact;
